import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@qga/roo-ui/components';
import Heading from '@/components/Heading';
import Text from '@/components/Text';

/**
 * @deprecated Use FormControl.tsx
 *
 * This component is being moved to Typescript. If there is functionality missing,
 * you should add it to the new component rather than using this one.
 *
 * apps/jetstar-holidays/src/v2/components/ui/FormControl/FormControl.tsx
 *
 * import FormControl from '@/v2/components/ui/FormControl';
 */
const FormControl = ({
  label,
  hint,
  children,
  optional,
  boldLabel,
  htmlFor,
  showInRow,
  ...props
}) => (
  <Flex
    width={['100%', null, null]}
    flexDirection={showInRow ? 'row' : 'column'}
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    <Flex alignItems="center" mb="1" mr={showInRow ? 3 : null}>
      <label htmlFor={htmlFor}>
        {boldLabel ? (
          <Heading.h6>{label}</Heading.h6>
        ) : (
          <Text bold>{label}</Text>
        )}
      </label>
      {hint && (
        <Text hint textStyle="small" ml="2">
          {hint}
        </Text>
      )}
    </Flex>
    {children}
    {optional && (
      <Text hint textStyle="small" mb="0" mt="2">
        Optional
      </Text>
    )}
  </Flex>
);

FormControl.defaultProps = {
  optional: false,
  boldLabel: false,
  showInRow: false,
};

FormControl.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  boldLabel: PropTypes.bool,
  showInRow: PropTypes.bool,
  ...Box.propTypes,
};

export default FormControl;
