import PropTypes from 'prop-types';
import React from 'react';
import { Select as BaseSelect, Box } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import ErrorMessage from '../ErrorMessage';

const StyledSelect = styled(BaseSelect)`
  border-color: ${(p) =>
    themeGet(p.error ? 'colors.text.error' : 'colors.border')};
  font-size: ${themeGet('fontSizes.sm')};
  border-radius: 3px;
  color: ${(p) => themeGet(p.value ? 'colors.text.body' : 'colors.text.hint')};
  option {
    color: ${themeGet('colors.text.body')};
  }
  &:focus {
    border-color: ${themeGet('colors.brand.secondary')};
  }
`;

const Select = ({ options, error, children, ...props }) => (
  <div>
    <StyledSelect
      error={!!error}
      mb="0"
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.text}
        </option>
      ))}
      {children}
    </StyledSelect>
    {error && (
      <Box mt={1}>
        <ErrorMessage>{error}</ErrorMessage>
      </Box>
    )}
  </div>
);

Select.defaultProps = {
  error: undefined,
  options: [],
};

Select.propTypes = {
  error: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  ...BaseSelect.propTypes,
};

export default Select;
