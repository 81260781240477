import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Icon } from '@qga/roo-ui/components';
import { getTravellersCount } from '@experiences-ui/shared/utils';
import TravellersFormDropdown from '@/components/Travellers/components/TravellersFormDropdown';
import Occupants from '../../propTypes/Occupants';
import Dropdown from '@experiences-ui/shared/components/Dropdown';
import FormControl from '../FormControl';
import Input from '../Input';
import ErrorMessage from '../ErrorMessage';

const Travellers = ({ occupants, onSubmit, error }) => {
  const travellers = getTravellersCount(occupants);

  return (
    <FormControl
      data-testid="TRAVELLERS"
      htmlFor="travellers"
      label="Travellers"
      width={[null, null, '180px']}
    >
      <Dropdown
        position="BOTTOM_RIGHT"
        toggle={(open, ref) => (
          <Box onClick={open} position="relative" ref={ref}>
            <Input
              data-testid="INPUT"
              id="travellers"
              readOnly
              error={error && Boolean(error.occupants)}
              color={travellers > 0 ? 'text.body' : 'text.hint'}
              value={
                travellers > 0
                  ? pluralize('traveller', travellers, true)
                  : 'Add travellers'
              }
            />
            <Flex
              position="absolute"
              top="0"
              right="0"
              bottom="0"
              alignItems="center"
            >
              <Icon
                name="person"
                size={24}
                mr={2}
                data-testid="TRAVELLER_ICON"
              />
            </Flex>
          </Box>
        )}
        content={(close) => (
          <TravellersFormDropdown
            occupants={occupants}
            onCancel={close}
            onSubmit={(data) => {
              onSubmit(data);
              close();
            }}
          />
        )}
      />
      {error && Boolean(error.occupants) && (
        <Box mt="1">
          <ErrorMessage>Please add travellers</ErrorMessage>
        </Box>
      )}
    </FormControl>
  );
};

Travellers.propTypes = {
  occupants: PropTypes.shape(Occupants).isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.shape({
    origin: PropTypes.string,
    destination: PropTypes.string,
    departureDate: PropTypes.string,
    returnDate: PropTypes.string,
    route: PropTypes.string,
    occupants: PropTypes.string,
  }).isRequired,
};

export default Travellers;
