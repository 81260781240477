import { Box, Flex, Icon } from '@qga/roo-ui/components';
import { isNotNilOrEmpty } from '@/lib/object';
import { longDate } from '@/lib/date/format';
import { SCREEN_SIZE } from '../../lib/theme/breakPoints';
import { themeGet } from 'styled-system';
import BlackoutDates from '@/propTypes/BlackoutDates';
import CampaignHeader from './components/CampaignHeader';
import ErrorMessage from '../ErrorMessage';
import FormControl from '@/components/FormControl';
import Input from '@/components/Input';
import LinkButton from '@/components/LinkButton';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import TermsAndConditionModal from '@/components/TermsAndConditionModal';
import Text from '@/components/Text';
import Dropdown from '@experiences-ui/shared/components/Dropdown';
import ToggleContent from '@experiences-ui/shared/v2/components/ToggleContent';
import useBreakpoints, { isScreen } from '@/hooks/useBreakpoints';
import useDestination from '@/hooks/useDestination';
import usePropertyContent from '@/hooks/usePropertyContent';
import useProcuredOffer from '@/components/PackagePage/hooks/useProcuredOffer';
import ViewDetailsModal from '@/components/ViewDetailsModal';
import { useDestination as v2UseDestination } from '@/v2/contexts/destination';

// SHARED COMPONENT
import TravelDatesCalendar from '@experiences-ui/shared/v2/components/TravelDatesCalendar';
import getProcuredOfferInclusions from '../DealsPage/utils/getProcuredOfferInclusions/getProcuredOfferInclusions';
import useCampaignPills from '@/hooks/useCampaignPills';
import { parseFlightDatesAndProcuredOfferDates } from '@/v2/services/sanity/utils/parser/campaign/parseCampaign';

const DateRangeInput = styled(Input)`
  color: ${(p) =>
    themeGet(p.hasReturnDate ? 'colors.text.body' : 'colors.text.hint')};

  ${SCREEN_SIZE.SM} {
    min-width: 330px;
  }
  border-color: ${(p) =>
    themeGet(p.error ? 'colors.text.error' : 'colors.border')};
`;

/**
 * @deprecated Use DateRangeInput.tsx
 *
 * apps/jetstar-holidays/src/v2/components/ui/Input/variants/DateRangeInput/DateRangeInput.tsx
 *
 * import Input from '@/v2/components/ui/Input/variants/DateRangeInput';
 */
const DateRange = ({
  initialDates,
  blackoutDates,
  minimumNights,
  campaign,
  onDataChange,
  originCode,
  destinationCode,
  error,
  isProcuredOffer,
  onCalendarFocus,
}) => {
  const [dates, setDates] = useState();
  const [rendered, setRendered] = useState(false);
  const [roomId, setRoomId] = useState(undefined);
  const { departureDate, returnDate } = dates || initialDates;

  const [isFocus, setIsFocus] = useState(false);
  const startDateRef = useRef(departureDate);
  const [startDate, setStartDate] = useState(startDateRef.current);

  const breakpoints = useBreakpoints();
  const smallScreen = isScreen(breakpoints)('xs', 'sm');
  const dropDownPosition = smallScreen ? 'BOTTOM_WITH_OFFSET' : 'BOTTOM_RIGHT';

  const [campaignMessage, setCampaignMessage] = useState('');

  const d = useDestination();
  const dV2 = v2UseDestination();

  const destination = d || dV2;
  const procuredOffer = useProcuredOffer();
  const { campaignPills } = useCampaignPills();

  const propertyContent = usePropertyContent() || {};

  const { id: propertyId, roomTypes } = propertyContent;

  const leadInOffer = procuredOffer?.rooms?.[0].leadInOffers?.[0];

  const [isTravelSelected, setIsTravelSelected] = useState(false);

  const [showCampaign, setShowCampaign] = useState(false);

  useEffect(() => {
    setShowCampaign((campaign && isNotNilOrEmpty(campaign.calendar)) ?? false);
  }, [campaign]);

  useEffect(() => {
    if (!originCode || (!destinationCode && !destination)) {
      setIsTravelSelected(false);
    } else {
      setIsTravelSelected(true);
    }
  }, [originCode, destinationCode, destination]);

  useEffect(() => {
    if (!showCampaign) return;

    const selectedRoute = campaign?.calendar?.routes?.find(
      (r) => r.route === `${originCode}${destinationCode}`,
    );

    if (selectedRoute) {
      if (campaign && isProcuredOffer) {
        const message = parseFlightDatesAndProcuredOfferDates(
          procuredOffer,
          selectedRoute,
          campaign,
        );

        if (!message) {
          setShowCampaign(false);
          return;
        }

        setCampaignMessage(message);
        return;
      }

      setCampaignMessage(selectedRoute.message);
      return;
    }

    setCampaignMessage(
      originCode
        ? campaign?.calendar?.unsupportedRouteMessage
        : campaign?.calendar?.noOriginMessage,
    );
  }, [
    campaign,
    destinationCode,
    originCode,
    isProcuredOffer,
    showCampaign,
    procuredOffer,
  ]);

  useEffect(() => {
    setRendered(true);
  }, []);

  const renderTravelDatesCalendar = (close) => {
    if (!isTravelSelected) return null;
    const submit = () => {
      onDataChange(dates);
      setDates();
      close();
    };

    const clearDates = () => {
      onDataChange({
        departureDate: undefined,
        returnDate: undefined,
      });
      setDates();
    };

    return (
      <TravelDatesCalendar
        campaignHeader={
          showCampaign && <CampaignHeader title={campaign.calendar.title} />
        }
        campaignMessage={showCampaign && campaignMessage}
        blackoutDates={blackoutDates}
        date={startDate}
        departureDate={departureDate}
        minimumNights={minimumNights}
        onClearDates={clearDates}
        onChange={(range) => {
          startDateRef.current = range.departureDate;
          setDates(range);
        }}
        onSubmit={submit}
        tandcs={renderTandCs()}
        returnDate={returnDate}
        submitText="Confirm"
        propertyId={propertyId}
        roomTypes={roomTypes?.map((room) => ({
          value: room.id,
          text: room.name,
        }))}
        roomId={roomId}
        brand="jetstar"
      />
    );
  };

  const renderTandCs = () => {
    if (isProcuredOffer) {
      return (
        <Box px={[4, null, 8]} pt={[3, null, 1]} pb={[3, null, 4]}>
          <ToggleContent
            toggle={(show) => (
              <LinkButton
                noPadding
                mb={[2, null, null]}
                fontSize="sm"
                onClick={(e) => {
                  e.preventDefault();
                  show();
                }}
              >
                <Text bold medium link>
                  View package details and terms{' '}
                  <Icon color="icon.inclusion" name="info" size={16} mb="2px" />
                </Text>
              </LinkButton>
            )}
            content={(close) => (
              <ViewDetailsModal
                close={close}
                title="Package details and terms"
                subtitle={`Holiday package to ${destination.title} at ${procuredOffer.name}`}
                terms={procuredOffer?.terms}
                inclusions={getProcuredOfferInclusions(
                  procuredOffer,
                  undefined,
                  campaignPills,
                )}
                minNights={leadInOffer?.minNumberOfNights}
                routes={procuredOffer?.routes}
                destinationCode={propertyContent.destination.destinationCode}
                travelDateRange={procuredOffer?.travelDateRange}
              />
            )}
          />
        </Box>
      );
    }

    if (!isProcuredOffer && campaign) {
      return (
        <Box px={[4, null, 8]} py="4">
          <ToggleContent
            toggle={(show) => (
              <LinkButton hover noPadding fontSize="sm" onClick={() => show()}>
                <Text>
                  {`${
                    campaign.sashing
                      ? `${campaign.sashing?.flightPillText} - `
                      : ''
                  }Sale terms `}
                  <Icon color="icon.inclusion" name="info" size={16} />
                </Text>
              </LinkButton>
            )}
            content={(closeModal) => (
              <TermsAndConditionModal
                close={closeModal}
                title={`${
                  campaign.sashing
                    ? `${campaign.sashing?.flightPillText} - `
                    : ''
                }Sale terms `}
                content={campaign.homePageHero.termsConditions}
              />
            )}
          />
        </Box>
      );
    }

    return false;
  };

  return (
    <Box data-testid="DATE_RANGE" position="relative">
      <Dropdown
        position={dropDownPosition}
        fullscreenOnMobile
        disabled={!isTravelSelected}
        toggle={(open, ref) => {
          const openWithStartDate = () => {
            setStartDate(startDateRef.current);
            open();
          };

          const clickCheckAvailabilityButtonHandler = (e) => {
            setRoomId(e.detail.roomId);
            open(true);
          };

          const eventHandlerController = (element) => {
            if (!element) {
              window.removeEventListener(
                'clickCheckAvailabilityButton',
                clickCheckAvailabilityButtonHandler,
              );
              return;
            }

            if (rendered)
              window.addEventListener(
                'clickCheckAvailabilityButton',
                clickCheckAvailabilityButtonHandler,
                {
                  once: true,
                },
              );
          };

          return (
            <Flex ref={ref}>
              <FormControl
                htmlFor="date-range"
                label="When"
                onFocus={() => {
                  onCalendarFocus(true);
                }}
                onBlur={() => {
                  onCalendarFocus(false);
                }}
              >
                <Box position="relative" ref={eventHandlerController}>
                  <DateRangeInput
                    data-testid="RETURN_DATE"
                    borderColor={isFocus ? 'borderOnFocus' : 'border'}
                    onClick={openWithStartDate}
                    value={
                      returnDate
                        ? `${longDate(departureDate)} - ${longDate(returnDate)}`
                        : 'Select travel dates'
                    } // eslint-disable-line max-len
                    hasReturnDate={!!returnDate}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}
                    id="date-range"
                    error={error && Boolean(error.returnDate)}
                    readOnly
                  />
                  <Flex
                    width="48px"
                    position="absolute"
                    top="12px"
                    right="0"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Icon
                      size={24}
                      name="calendar"
                      onClick={openWithStartDate}
                    />
                  </Flex>
                  {error && Boolean(error.returnDate) && (
                    <Box mt="1">
                      <ErrorMessage>Please select travel dates</ErrorMessage>
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Flex>
          );
        }}
        content={(close) => {
          return renderTravelDatesCalendar(close);
        }}
      />
    </Box>
  );
};

DateRange.defaultProps = {
  blackoutDates: {},
  minimumNights: 0,
  originCode: undefined,
  destinationCode: undefined,
  campaign: undefined,
  error: {},
  isProcuredOffer: false,
};

DateRange.propTypes = {
  minimumNights: PropTypes.number,
  blackoutDates: PropTypes.shape(BlackoutDates),
  onDataChange: PropTypes.func.isRequired,
  originCode: PropTypes.string,
  destinationCode: PropTypes.string,
  campaign: PropTypes.object,
  initialDates: PropTypes.shape({
    departureDate: PropTypes.string,
    returnDate: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  isProcuredOffer: PropTypes.bool,
};

export default DateRange;
