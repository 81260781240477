import { useCallback } from 'react';

const useScrollIntoView = (selector, offset = 60) => {
  return useCallback(
    (opts) => {
      const element = document.querySelector(selector);

      if (!element) {
        return;
      }

      element.scrollIntoView(opts);
      if (offset) {
        window.scrollBy(0, -offset);
      }
    },
    [offset, selector],
  );
};

export default useScrollIntoView;
