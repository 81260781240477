import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@qga/roo-ui/components';
import Alert from '@/components/Alert';
import Text from '@/components/Text';

const RouteErrorMessage = ({ destination }) => (
  <Alert.Info icon={{ name: 'info', color: 'brand.secondary' }} mt={6}>
    <Box>
      <Text bold>{`We can't find your ${destination.title} holiday`}</Text>
    </Box>
    <Box>
      <Text data-testid="ERROR_MESSAGE">
        {'Try changing your travel dates or check out other packages in '}
        <Text bold color="secondary">
          {destination.title}
        </Text>
        {'. '}
      </Text>
    </Box>
  </Alert.Info>
);

RouteErrorMessage.defaultProps = {
  destination: {
    name: '',
    title: '',
  },
};

RouteErrorMessage.propTypes = {
  destination: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default RouteErrorMessage;
