import React from 'react';
import PropTypes from 'prop-types';
import { Input as BaseInput, Box } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet, color } from 'styled-system';
import ErrorMessage from '../ErrorMessage';

const StyledInput = styled(BaseInput)`
  font-size: ${themeGet('fontSizes.sm')};
  border-radius: 3px;
  &:focus {
    border-color: ${themeGet('colors.brand.secondary')};
  }
  ${color}
`;

/**
 * @deprecated Use Input.tsx
 *
 * apps/jetstar-holidays/src/v2/components/ui/Input/Input.tsx
 *
 * import Input from '@/v2/components/ui/Input';
 */
const Input = ({ error, value, width, ...props }) => (
  <>
    <Box width={width}>
      <StyledInput
        value={value}
        bg={value ? 'background.card' : 'background.page'}
        error={!!error}
        mb="0"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Box>
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </>
);

Input.defaultProps = {
  error: undefined,
};

Input.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  ...Input.propTypes,
};

export default Input;
